import type { MetaTagsProps } from 'svelte-meta-tags';

const DEFAULT_TITLE = 'Tickrr | Modern Charts & Market Analysis Tools';
export const DEFAULT_OG_TITLE = 'Tickrr | Modern Charts & Market Analysis Tools'; // Default title in OG cards
export const DEFAULT_OG_DESCRIPTION =
	'Tickrr is a modern charting and market analysis platform for investors. Join now to invest with confidence and increase your returns!';

const CANONICAL_URL = 'https://tickrr.io/';

export const OG_IMAGE_WIDTH: number = 1200;
export const OG_IMAGE_HEIGHT: number = 630;
// TODO: Update OG image.
// FIXME:
export const DEFAULT_OG_IMAGE_URL = `${import.meta.env.VITE_WEBAPP_URL}/og-image-4x.webp`;
// export const DEFAULT_OG_IMAGE_URL = constructTwicpicsUrl('/static/og-image-2.png', {
// 	height: OG_IMAGE_HEIGHT,
// 	quality: 100,
// 	width: OG_IMAGE_WIDTH
// });

/**
 * Used with svelte-meta-tags.
 * This allows us to easily override existing meta tags in the <head> of the document,
 * depending on the page that is being rendered.
 * @see https://github.com/oekazuma/svelte-meta-tags.
 */
export const defaultMetaTagInfo: MetaTagsProps = {
	canonical: CANONICAL_URL,
	description: DEFAULT_OG_DESCRIPTION,
	facebook: {
		appId: '1469330997191561'
	},
	openGraph: {
		description: DEFAULT_OG_DESCRIPTION,
		images: [
			{
				alt: 'Tickrr.io App Screenshots',
				height: 630,
				url: DEFAULT_OG_IMAGE_URL,
				width: 1200
			}
		],
		siteName: 'tickrr.io',
		title: DEFAULT_OG_TITLE,
		url: CANONICAL_URL
	},
	title: DEFAULT_TITLE,
	titleTemplate: '%s',
	twitter: {
		cardType: 'summary_large_image',
		description: DEFAULT_OG_DESCRIPTION,
		handle: '@tickrr_io',
		image: DEFAULT_OG_IMAGE_URL,
		imageAlt: 'Tickrr.io App Screenshots',
		site: '@tickrr_io',
		title: DEFAULT_OG_TITLE
	}
};
